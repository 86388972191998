<template>
  <div>
    <v-row class="d-flex justify-center mx-1 mt-md-4 mt-1">
      <v-col cols="12" sm="10" xl="8">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="3">
            <Index :initiallyOpen="['Empezar', 'Introducción', 'Instalación']" />
          </v-col>
          <v-col cols="12" md="9" class="text-left">

            <h1>Introducción</h1>
            <a href="#que-es-rosy-ide" class="index" @click="$vuetify.goTo('#que-es-rosy-ide')">
              <h2 id="que-es-rosy-ide" class="py-2">¿Qué es Rosy IDE?</h2>
            </a>
            <p>
              Rosy IDE es una <strong>plataforma de software libre</strong> que tiene por objetivo <strong>facilitar y simplificar</strong>
              el desarrollo de proyectos que utilicen Arduino, mediante el uso y la implementación de un lenguaje
              de programación propio, una interfaz gráfica moderna y un potente compilador.
            </p>
            <p>
              El nombre Rosy proviene del juego de palabras
              <strong class="font-italic"><span class="red--text">Ro</span>bot ea<span class="success--text">sy</span></strong>.
            </p>
            <a href="#por-que-utilizar-rosy-ide" class="index" @click="$vuetify.goTo('#por-que-utilizar-rosy-ide')">
              <h2 id="por-que-utilizar-rosy-ide" class="py-2">¿Por qué utilizar Rosy IDE?</h2>
            </a>
            <p>
              La principal característica que diferencia a Rosy IDE de otras plataformas dedicadas a Arduino, es la
              posibilidad de <strong>almacenar, de manera sencilla, diversas configuraciones de pines</strong> para la placa.
            </p>
            <p>
              Con este fin se utiliza el sub-lenguje Pini en conjunto con una interfaz gráfica que ayuda a su codificación.
              Así, se puede obtener una sola configuración de pines para un mismo robot que ejecute dos lógicas
              diferentes y se logre <strong>reducir el código</strong> y <strong>evitar la redundancia</strong>.
            </p>
            <p>
              Otra ventaja de utilizar Rosy IDE es su innovador lenguaje de programación. El mismo fue diseñado de una manera coloquial
              con el objetivo de <strong>evitar el uso excesivo de símbolos</strong> tales como <strong class="font-italic">; : {} []</strong>
              que causan confusión y generan errores de sintaxis molestos para la mayoría de programadores inexpertos.
            </p>
            <a href="#el-lenguaje-de-rosy-ide" class="index" @click="$vuetify.goTo('#el-lenguaje-de-rosy-ide')">
              <h2 id="el-lenguaje-de-rosy-ide" class="py-2">El lenguaje de Rosy IDE</h2>
            </a>
            <p>
              Rosy IDE incorpora un nuevo lenguaje de programación llamado <strong>Rosy</strong>, que simplifica y reduce el código necesario para ejecutar
              un programa en Arduino. <a href="/documentacion/lenguaje#rosy">Aprende la sintaxis de Rosy.</a>
            </p>
            <p>
              A su vez, Rosy hace uso de <strong>Pini</strong>, un sub-lenguaje que facilita y modula la configuración de los pines de la placa
              y permite su almacenamiento y reutilización. <a href="/documentacion/lenguaje#pini">Aprende más sobre Pini.</a>
            </p>
            <p>
              <v-row class="my-2">
                <v-col cols="12" md="5">
                  <div class="d-flex align-center">
                    <img src="../../src/assets/rosy.webp" style="height:100px; width:100px" title="Rosy" />
                    <img src="../../src/assets/examples/blink_rosy.svg" />
                  </div>
                  <div class="d-flex justify-center pt-3">
                    <v-icon color="info" large>fas fa-plus</v-icon>
                  </div>
                  <div class="d-flex align-center">
                    <img src="../../src/assets/pini.webp" style="height:100px; width:100px" title="Pini" />
                    <img
                      :style="$vuetify.breakpoint.xs ? 'width: 225px' : ''"
                      src="../../src/assets/examples/blink_pini.svg"
                      />
                  </div>
                </v-col>
                <v-col cols="12" md="1" class="d-flex align-center justify-center">
                  <v-icon color="success" large>fas fa-equals</v-icon>
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <img src="../../src/assets/arduino.webp" class="mx-md-3" style="height:75px; width:75px" title="Arduino" />
                  <img
                    :style="$vuetify.breakpoint.xs ? 'width: 250px' : ''"
                    src="../../src/assets/examples/blink_cpp.svg"
                    />
                </v-col>
              </v-row>
            </p>
            <p>
              El compilador de Rosy IDE <strong>genera un archivo</strong> de extensión <strong class="font-italic">.ino</strong>
              listo para ser ejecutado en el <a href="https://www.arduino.cc/en/software" target="_blank">software de Arduino IDE</a>,
              el cual es necesario para implementar el código desarrollado en la placa Aduino.
            </p>

            <h1>Instalación</h1>
            <a href="#windows" class="index" @click="$vuetify.goTo('#windows')">
              <h2 id="windows" class="py-2"><v-icon left class="pb-1" color="blue">fab fa-windows</v-icon>Windows</h2>
            </a>
            <p>
              <strong>1.</strong>
              Una vez descargado el archivo, descomprímalo en el disco <code>C:\</code> de su equipo (o en la ubicación que usted prefiera)
            </p>
            <p>
              <strong>2.</strong>
              Abra la carpeta <code>rosy-ide-1.0</code> y busque el archivo llamado <code>Rosy IDE.exe</code>
            </p>
            <p>
              <strong>3.</strong>
              Cree un acceso directo haciendo click derecho sobre el archivo y elija la opción
              <code>Enviar a <strong>-></strong> Escritorio (crear acceso directo)</code>
            </p>
            <a href="#debian-ubuntu" class="index" @click="$vuetify.goTo('#debian-ubuntu')">
              <h2 id="debian-ubuntu" class="py-2"><v-icon left class="pb-1" color="orange">fab fa-ubuntu</v-icon>Debian/Ubuntu</h2>
            </a>
            <p>
              Una vez descargado el archivo, descomprímalo en el directorio <code>/opt</code> con el comando:
            </p>
            <p class="text-center">
              <code>$ sudo tar -xzvf rosy-ide-1.0.tar.gz -C /opt/</code>
            </p>
            <p>
              Para crear un acceso directo ejecute el comando:
            </p>
            <p class="text-center">
              <code>$ sudo nano /usr/share/applications/rosy-ide.desktop</code>
            </p>
            <p>
              Luego copie el siguiente texto dentro del archivo y guarde los cambios
            </p>
            <p class="text-center">
              <iframe
                src="https://carbon.now.sh/embed?bg=rgba%28255%2C255%2C255%2C1%29&t=one-light&wt=none&l=application%2Fx-sh&ds=true&dsyoff=2px&dsblur=9px&wc=false&wa=true&pv=9px&ph=14px&ln=false&fl=1&fm=Hack&fs=14px&lh=133%25&si=false&es=2x&wm=false&code=%255BDesktop%2520Entry%255D%250AType%253DApplication%250AName%253DRosy%2520IDE%250AComment%253DUn%2520entorno%2520de%2520desarrollo%2520integrado%2520para%2520proyectos%2520Arduino%250AExec%253D%2522%252Fopt%252Frosy-ide-1.0%252Frosy-ide%2522%250AIcon%253D%252Fopt%252Frosy-ide-1.0%252Ficon.xpm%250ATerminal%253Dfalse%250ACategories%253DDevelopment%253BIDE%253BCode%253B"
                style="width: 100%; height: 250px; border:0; transform: scale(1); overflow:hidden;"
                sandbox="allow-scripts allow-same-origin">
              </iframe>
            </p>

            <a href="#listo-para-mas" class="index" @click="$vuetify.goTo('#listo-para-mas')">
              <h1 id="listo-para-mas" class="py-2">¿Listo para más?</h1>
            </a>
            <p></p>
            <p>
              • <a href="/documentacion/lenguaje" class="ml-2">Aprenda el lenguaje de Rosy IDE</a>
            </p>
            <p>
              • <a href="/documentacion/ejemplos" class="ml-2">Ver los ejemplos</a>
            </p>

            <div class="d-flex mt-8">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="info"
                class="body-1"
                style="text-transform: none"
                to="/documentacion/lenguaje"
              >
                Siguiente: Lenguaje
                <v-icon right>fas fa-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Index from '../components/Index'

export default {
  title: 'Documentación',
  mounted() {
    if (this.$route.hash) this.$vuetify.goTo(this.$route.hash)
    else this.$vuetify.goTo(0)
  },
  components: {
    Index
  },
}
</script>